import 'react-day-picker/dist/style.css';

import PropTypes from 'prop-types';
import React from 'react';
import { DayPicker } from 'react-day-picker';

import Button from '~/components/Button';
import { FormattedDate } from '~/components/Intl';
import Popover, { Position } from '~/components/Popover';
import Text from '~/components/Text';

import DatePickerStyler from './DatePickerStyler';
import { afterToday } from './modifiers';

class RangePicker extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.shape({
      from: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
      to: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    }),
    prefix: PropTypes.string,
    suffix: PropTypes.string,
  };

  static defaultProps = {
    value: { from: new Date(), to: new Date() },
    disabledDays: afterToday,
    numberOfMonths: 2,
  };

  state = {
    value: this.props.value,
  };

  handleSelectRange = (range, selectedDay, _modifiers, _e) => {
    // Set the range
    if (range) {
      this.setState({ value: range });
    } else {
      this.setState({ value: { from: selectedDay, to: selectedDay } });
    }
  };

  render() {
    const {
      prefix,
      suffix,
      placeholder,
      onChange,
      value: controlledValue,
      disabledDays: disabled,
      numberOfMonths,
      ...props
    } = this.props;
    const { value } = this.state;
    const isSingleDay = value.from?.toString() === value.to?.toString();
    const dayPickerProps = {
      ...props,
      mode: 'range',
      selected: value,
      onSelect: this.handleSelectRange,
      disabled,
      numberOfMonths,
    };

    let button;

    if (!value.from && !value.to && placeholder) {
      button = <Button type="button">{placeholder}</Button>;
    } else {
      button = (
        <Button type="button" {...props}>
          {prefix}
          {isSingleDay ? (
            <FormattedDate value={value.from} />
          ) : (
            <>
              <FormattedDate value={value.from} /> &mdash;&nbsp;
              <FormattedDate value={value.to} />
            </>
          )}
          {suffix}
        </Button>
      );
    }

    return (
      <Popover
        position={Position.BOTTOM_LEFT}
        content={({ close }) => (
          <DatePickerStyler>
            <DayPicker {...dayPickerProps} />
            <Text p={3} textAlign="right">
              <Button
                appearance="minimal"
                onClick={(_e) => {
                  onChange({ from: null, to: null });
                  this.handleSelectRange();
                  close();
                }}
              >
                Clear
              </Button>
              <Button
                intent="success"
                appearance="primary"
                onClick={() => {
                  onChange(value);
                  close();
                }}
              >
                Confirm
              </Button>
            </Text>
          </DatePickerStyler>
        )}
      >
        {button}
      </Popover>
    );
  }
}

export default RangePicker;
