import styled, { themed } from '~/lib/styled';

const DatePickerStyler = styled('div')(
  themed({
    '.rdp-root': {
      mx: 3,
    },
  })
);

export default DatePickerStyler;
