import 'react-day-picker/dist/style.css';

import { isDate } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { DayPicker } from 'react-day-picker';

import DatePickerStyler from './DatePickerStyler';
import { beforeToday } from './modifiers';

export default class DatePicker extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  };

  static defaultProps = {
    value: new Date(),
    disabledDays: beforeToday,
  };

  handleDayClick = (day) => {
    // Normalize event
    this.props.onChange({ target: { value: day } });
  };

  render() {
    const selectedDay = new Date(this.props.value);

    if (!isDate(selectedDay)) {
      return null;
    }

    return (
      <DatePickerStyler>
        <DayPicker
          mode="single"
          selected={selectedDay}
          disabled={this.props.disabledDays}
          onSelect={this.handleDayClick}
        />
      </DatePickerStyler>
    );
  }
}
