import Box from '~/components/Box';
import styled from '~/lib/styled';

function buttonGroupStyle({ collapseBorder = true }) {
  return {
    display: 'inline-block',

    '& > :not(:first-child):not(:last-child)': {
      borderRadius: 0,
    },

    '& > :first-child:not(:last-child)': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },

    '& > :last-child:not(:first-child)': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },

    '& > :last-child:not(:first-child), & > :not(:first-child):not(:last-child)':
      collapseBorder == true
        ? {
            marginLeft:
              '-1px' /* hide the left border behind previous element */,
          }
        : {},
  };
}

buttonGroupStyle.propNames = ['collapseBorder'];

export const ButtonGroup = styled(Box, { mr: '4px' })(buttonGroupStyle);

export default ButtonGroup;
